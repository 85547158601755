import React from 'react';

export default class DownloadProviderApp extends React.Component {
    render() {
        return (
            <div style={{ flex: 1, height: '100%', width: '100%', background: '#13b5fd', justifyContent: 'center', padding: 30, display: 'flex', }}>
                <div>
                    <img src={window.location.origin + '/assets/images/beeclean-provider-logo.png'} alt={"downloadAndoid"} style={{ maxWidth: '100%' }} /><br />
                    <a style={{ textAlign: 'center' }} href={window.location.origin + '/assets/app-release-provider.apk'} download={true}>
                        <img src={window.location.origin + '/assets/images/download-android.png'} alt={"downloadAndoid"} style={{ maxWidth: '100%', width: 270 }} />
                    </a>
                </div>
            </div>
        )
    }
}