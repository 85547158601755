import React from 'react';

export default class Service extends React.Component {
    render() {
        return (
            <>
            <div class="container content-bg">
                <div class="header">บริการ</div>
            </div>
            </>
        );
    }
}