import React from 'react';

export default class Customer extends React.Component {
    render() {
        return (
            <>
            <div class="container content-bg">
                <div class="header">ลูกค้า</div>
            </div>
            </>
        );
    }
}