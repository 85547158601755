import React from 'react';
import { db, storage, generateBandLives, deleteBandLives } from '../firebase';

export default class Brand extends React.Component {
    constructor() {
        super();
        this.state = {
            brand: false,
            music: [],
            tips: [],
            is_loading: true,
        }
    }

    componentDidMount() {
        const bandLivesListener = db.doc(`band_lives/XAUmBN9V9i6cHNWgNgqV`).onSnapshot(async snapDoc => {

            let live = false;
            console.log(snapDoc.exists)
            if (snapDoc.exists) {
                live = true;
                db.doc(`bands/${snapDoc.data().band_uid}`).get().then(bandDoc => {
                    this.setState({
                        brand: {
                            ...snapDoc.data(),
                            ...bandDoc.data(),
                        }
                    })
                })

            }
        });

        const musicListener = db.collection(`band_lives/XAUmBN9V9i6cHNWgNgqV/request_musics`).orderBy('createdAt', 'asc').onSnapshot(async rmSnap => {
            let rm = rmSnap.docs.map(documentSnapshot => {
                return {
                    id: documentSnapshot.id,
                    ...documentSnapshot.data()
                };
            });
            let tm = rmSnap.docs.map(documentSnapshot => {
                return {
                    id: documentSnapshot.id,
                    ...documentSnapshot.data()
                };
            });

            rm.forEach((element, key) => {
                if (element.isForcePlay && !element.isDone) {
                    let temp = rm.pop(key);
                    rm.unshift(temp)
                    return;
                }
            })

            this.setState({
                music: rm,
                tips: tm.sort((a, b) => parseFloat(b.tip) - parseFloat(a.tip)),
                is_loading: false,
            })
        });

        window.$(".footer").remove();
        // const userListener = db.doc(`users/${user.uid}`).onSnapshot(async snapDoc => {

        //     let live = false;
        //     if (snapDoc.exists) {
        //         if (snapDoc.data().token) {
        //             setHaveTip(snapDoc.data().token);
        //         } else {
        //             setHaveTip(0);
        //         }
        //     }
        //     setRefresh(!refresh);
        // });
        // return () => { bandLivesListener(), musicListener(), userListener() };

    }

    numberFormat(number) {
        number = parseFloat(number);
        if (isNaN(number)) {
            number = 0;
        }
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        let max_tip = false;
        this.state.tips.map((tip) => {
            if (max_tip && tip.tip > max_tip.tip && tip.tip !== 0) {
                max_tip = tip
            }
            if (!max_tip && tip.tip !== 0) {
                max_tip = tip
            }
        });
        let count = 0;
        return (
            <div className={"page_content"} style={{ paddingTop: 80, paddingBottom: 5 }}>
                <div className={"container"}>
                    {
                        !this.state.brand ?
                            <h1 style={{ textAlign: 'center' }}>ไม่มีวงดลตรีเล่น</h1>
                            :
                            <div className={"brand-grid"}>
                                <div>
                                    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        {
                                            this.state.brand ?
                                                <div className={"brand-up"}>
                                                    <div>
                                                        <img src={this.state.brand.photoURL} alt={"brand"} />
                                                    </div>
                                                    <div className={"brand-details"}>
                                                        <h3>{this.state.brand.displayName}</h3>
                                                        <p><i className="far fa-clock"></i> เริ่มเล่น {new Date(this.state.brand.startAt).getHours() + ":" + ("0" + new Date(this.state.brand.startAt).getMinutes()).substr(-2)} น.</p>
                                                    </div>
                                                </div>
                                                : null
                                        }

                                        {
                                            max_tip ?
                                                <>
                                                    <h3 className={"ui dividing header"}>จำนวนทิปสูงสุด</h3>
                                                    <div className={"brand-card black"} style={{ marginBottom: 10 }}>
                                                        <div className={"brand-card-no"}>
                                                            <i className="fas fa-trophy"></i>
                                                        </div>
                                                        <div className={"brand-card-content"} style={{ alignItems: 'center', lineHeight: 1 }}>
                                                            <h4 style={{ marginBottom: 0, lineHeight: 1 }}>{max_tip.userDisplayName} {this.numberFormat(max_tip.tip)} tips
                                                                <br />
                                                                <span style={{ fontSize: 14, color: '#ccc' }}>
                                                                    {new Date(max_tip.createdAt).getDate() + '/' +
                                                                        new Date(max_tip.createdAt).getMonth() + '/' +
                                                                        new Date(max_tip.createdAt).getFullYear() + ' ' +
                                                                        new Date(max_tip.createdAt).getHours() + ":" +
                                                                        ("0" + new Date(max_tip.createdAt).getMinutes()).substr(-2)}</span>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }

                                        {
                                            this.state.tips.length > 0 ?
                                                <ul >
                                                    {
                                                        this.state.tips.map((tip, tip_i) => tip.tip > 0 ?
                                                            <li key={tip_i} style={{ marginBottom: 5, lineHeight: 1, marginBottom: 5 }}>
                                                                {tip.userDisplayName} {this.numberFormat(tip.tip)} tips<br />
                                                                <span style={{ fontSize: 12, color: '#ccc' }}>
                                                                    {new Date(tip.createdAt).getDate() + '/' +
                                                                        new Date(tip.createdAt).getMonth() + '/' +
                                                                        new Date(tip.createdAt).getFullYear() + ' ' +
                                                                        new Date(tip.createdAt).getHours() + ":" +
                                                                        ("0" + new Date(tip.createdAt).getMinutes()).substr(-2)}
                                                                </span>
                                                            </li>
                                                            : null
                                                        )
                                                    }
                                                </ul>
                                                : null
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        <h2 className={"ui header"}>รายการขอเพลง</h2>
                                        <div style={{ padding: 15, background: '#f4f4f4', borderRadius: 10, minHeight: 660 }}>
                                            <div className={"song-list"}>
                                                {
                                                    !this.state.is_loading
                                                        && this.state.music.length > 0 ?
                                                        this.state.music.map((musicList, music_i) => {
                                                            if (musicList.isDone) { return null }
                                                            count++;
                                                            if (count > 6) { return null }
                                                            return (
                                                                <div style={{ marginTop: count > 1 ? 15 : 0 }} key={music_i}>
                                                                    <div className={"brand-card"}>
                                                                        <div className={"brand-card-no"}>
                                                                            {count}
                                                                        </div>
                                                                        <div className={"brand-card-content"}>
                                                                            <div className={"brand-card-icon"}>
                                                                                <i className="fas fa-music"></i>
                                                                            </div>
                                                                            <div className={"brand-card-details"}>
                                                                                <div><strong>{musicList.name}</strong></div>
                                                                                <div>{musicList.description}</div>
                                                                                <div>{musicList.userDisplayName}</div>
                                                                            </div>
                                                                            {
                                                                                musicList.isPlaying ?
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'column',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                                        <i class="far fa-play-circle" style={{ fontSize: 30 }}></i>
                                                                                    กำลังเล่น
                                                                                </div>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : null
                                                }
                                                {
                                                    !this.state.is_loading && this.state.music.length === 0 ?
                                                        <div>
                                                            <div className={"brand-card"}>
                                                                <div className={"brand-card-no"}>
                                                                    <i className="fas fa-times"></i>
                                                                </div>
                                                                <div className={"brand-card-content"}>
                                                                    <div className={"brand-card-icon"}>
                                                                        <i className="fas fa-music"></i>
                                                                    </div>
                                                                    <div className={"brand-card-details"}>
                                                                        <div><strong>ไม่มีรายการขอเพลง</strong></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }

                </div>
            </div>
        );
    }
}