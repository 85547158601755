import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import PrivacyPolicy from './page/PrivacyPolicy';
import Terms from './page/Terms';
import Register from './page/Register';
import RegisterSuccess from './page/RegisterSuccess';
import Brand from './page/Brand';
import DatePicker from "react-datepicker";
import StatusChecking from './page/StatusChecking';
import Customer from './page/Customer';
import Promotion from './page/Promotion';
import PromotionList from './page/PromotionList';
import Price from './page/Price';
import Tos from './page/Tos';
import toAppProvider from './page/toAppProvider';
import toAppUser from './page/toAppUser';
import Service from './page/Service';
import CustomDateInput from './Components/CustomDateInput';
import { Helper } from './Helper';
import InputMask from 'react-input-mask';

import Download from './page/Download';
import DownloadProvider from './page/DownloadProvider';
import DownloadUserApp from './page/DownloadUserApp';
import DownloadProviderApp from './page/DownloadProviderApp';
import OmiseComplete from './page/OmiseComplete';
import OmiseVoid from './page/OmiseVoid';

import "react-datepicker/dist/react-datepicker.css";
// import { ReCAPTCHA } from "react-google-recaptcha";
import Reaptcha from 'reaptcha';
import { db } from "./firebase";
window.months_th = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม",];
window.api_host = 'https://asia-southeast2-beeclean-app-66b97.cloudfunctions.net/app';
// window.api_host = 'https://asia-southeast2-beeclean-app.cloudfunctions.net/app';
window.base_url = window.location.origin;

if (window.location.origin.indexOf('localhost:') !== -1) {
   window.api_host = "http://127.0.0.1:5001/beeclean-app-66b97/asia-southeast2/app";
}
window.mainSide = `https://beeclean.app`;
//  window.api_host = "http://localhost:5001/beeclean-app/asia-southeast2/app";
export default function App() {
  // console.log(window.location);
  // const reload = () => window.location.reload();
  useEffect(() => {
    // console.log(window.location.origin)
    let origin_url = window.location.origin;
    // origin_url = `https://download.beeclean.app/`;
    let check_download = origin_url.indexOf('download.beeclean.app');
    if (check_download > -1 && window.location.pathname != '/download') {
      window.location = "https://beeclean.app/download"
    }
    let check_download_provider = origin_url.indexOf('download-provider.beeclean.app');
    if (check_download_provider > -1 && window.location.pathname != '/download-provider-app') {
      window.location = "https://beeclean.app/download-provider"
    }
    let check_download_quiz = origin_url.indexOf('quiz.beeclean.app');
    if (check_download_quiz > -1) {
      window.location = "https://docs.google.com/forms/d/1WmBNb0UEiDDfEaU_4Gedk7QwlTbwsCGnr_rg0ne2oh8/viewform?edit_requested=true"
    }
    let check_download_recruit = origin_url.indexOf('recruit.beeclean.app');
    if (check_download_recruit > -1 && window.location.pathname != '/register') {
      window.location = "https://beeclean.app/register"
    }
  }, [])
  return (
    <Router>
      {

        window.location.pathname !== '/download-provider-app'
          && window.location.pathname !== '/download-user-app'
          && window.location.pathname !== '/booking-unit-total'
          && window.location.pathname !== '/application-tos'
          && window.location.pathname !== '/application-privacy-policy'
          && window.location.pathname !== '/booking-unit-total'
          && window.location.pathname !== '/loading' ?
          <Header />
          : null
      }
      <div className="content">
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/register" component={Register} />
          <Route path="/register-success" component={RegisterSuccess} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms" component={Terms} />
          <Route path="/brand" component={Brand} />
          <Route path="/status-checking/:users_id" component={StatusChecking} />
          <Route path="/customer" component={Customer} />
          <Route path="/service" component={Service} />
          <Route path="/price" component={Price} />
          <Route path="/tos" component={Tos} />
          <Route path="/application-tos" component={Tos} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/application-privacy-policy" component={PrivacyPolicy} />
          <Route path="/customer" component={Customer} />
          <Route path="/download" component={Download} />
          <Route path="/download-provider" component={DownloadProvider} />
          <Route path="/omise-complete" component={OmiseComplete} />
          <Route path="/omise-void" component={OmiseVoid} />
          <Route path="/to-app-provider" component={toAppProvider} />
          <Route path="/to-app-user" component={toAppUser} />
          <Route path="/download-provider-app" component={DownloadProviderApp} />
          <Route path="/download-user-app" component={DownloadUserApp} />
          <Route path="/booking-unit-total" component={BookingTotal} />
          <Route path="/promotion/:promotion_code" component={Promotion} />
          <Route path="/promotion" component={PromotionList} />
          <Route path="/loading" component={Loading} />
        </Switch>
      </div>
      {
        window.location.pathname !== '/download-provider-app'
          && window.location.pathname !== '/download-user-app'
          && window.location.pathname !== '/application-tos'
          && window.location.pathname !== '/application-privacy-policy'
          && window.location.pathname !== '/booking-unit-total'
          && window.location.pathname !== '/loading' ?
          <Footer />
          : null
      }
    </Router>
  );
}
class Loading extends React.Component {
  constructor() {
    super();
    let merchantReference = Helper.getParameterByName("merchantReference") || false;
    this.state = {
      merchantReference: merchantReference,
      render_type: 'loading'
    }
  }
  componentDidMount() {
    this.init();
  }
  init() {
    if (this.state.merchantReference) {
      this.onSnapWegate = db.collection('onSnapWegate').doc(this.state.merchantReference).onSnapshot(e => {
        if (e.exists) {
          console.log('wait for noti')
          let paymentStatusCode = e.data().paymentStatusCode;
          if (paymentStatusCode) {
            if (paymentStatusCode == "004") {
              this.setState({ render_type: "success" })
            } else if (paymentStatusCode == "006" || paymentStatusCode == "007" || paymentStatusCode == "099") {
              this.setState({ render_type: "error" })
            }
          }
        } else {
          this.setState({ render_type: "loading" })
        }
      })
    }
  }

  linktoapp() {
    // window.opener = null;
    // window.open('', '_self');
    // window.close();
    window.location.href = "beecleanuser://beeclean";
    // setTimeout(
    //   function () {
    //     window.location.href = "https://beeclean-frontend.web.app/home";
    //   }
    //     .bind(this),
    //   3000
    // );
  }

  render() {
    return (
      <>
        <div className="container content-bg omise-complete-ui">
          <div className={"title"}>
            {
              this.state.render_type == 'success' ?
                <>
                  <div style={{ textAlign: 'center', marginBottom: 25 }}>
                    <img src={window.base_url + "/assets/images/15.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                  </div>
                  <div>
                    <div style={{ color: 'green' }}>ชำระเงินสำเร็จ</div>
                    <small style={{ color: 'green', opacity: 0.7 }}>Payment successfully</small>
                  </div>
                </>
                : null
            }
            {
              this.state.render_type == 'error' ?
                <>
                  <div style={{ textAlign: 'center', marginBottom: 25 }}>
                    <img src={window.base_url + "/assets/images/29.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                  </div>

                  <div>
                    <div style={{ color: 'red' }}>ชำระเงินไม่สำเร็จ</div>
                    <small style={{ color: 'red', opacity: 0.7 }}>Payment Failed</small><br />
                    <small style={{ opacity: 0.7 }}>โปรดกลับไปเพื่อทำการชำระเงินใหม่อีกครั้ง</small>
                  </div>
                </>
                : null
            }
            {
              this.state.render_type == 'loading' ?
                <>
                  <div style={{ textAlign: 'center', marginBottom: 25 }}>
                    <img src={window.base_url + "/assets/images/9.png"} alt={"Success Icon"} style={{ maxWidth: '70%', width: '400px' }} />
                  </div>
                  <>
                    <div>
                      <div className="text-primary">กรุณารอสักครู</div>
                      <small className="text-primary" style={{ opacity: 0.7 }}>Please wait</small><br />
                      <small style={{ opacity: 0.7 }}>ระบบกำลังทำการยืนยันการชำระเงิน</small>
                    </div>
                  </>
                </>
                : null
            }
            <br />
            <button className={"beeclean-button btn"} style={{ width: 'auto' }} onClick={this.linktoapp}>กดเพื่อกลับไปยัง Application</button>
          </div>
        </div>
      </>
    )
  }
}
function ErrorPage() {
  return (<>
    Page Not Found
  </>)
}
function Header() {
  let history = useHistory();
  useEffect(() => {
    // console.log(window.location)
    if (history.location.pathname === '/') {
      window.location.href = window.location.origin + "/home";
    }
  }, []);
  return (
    <>
      <div className={"top-header"}>
        <div className="flex row">
          <div className="right floated">
            Custom Service 02-027-7848
          </div>
        </div>
      </div>
      <div className={"header-bg"}>
        <div className="flex row middle-xs">
          <div className="flex row middle-xs">
            <div className={"logo"}>
              <a href={window.mainSide}>
                <img src={window.base_url + "/assets/images/logo.png"} alt={"LOGO"} />
              </a>
              {/* <Link to={"/home"}>
                <img src={window.base_url + "/assets/images/logo.png"} alt={"LOGO"} />
              </Link> */}
            </div>
            <div>
              บริการแม่บ้าน ออนไลน์ มืออาชีพ<br />
              ให้บริการทำความสะอาดบ้าน คอนโด ออฟฟิศ
            </div>
          </div>
          {
            history.location.pathname.indexOf("/omise-complete") === -1 ?
              <div className="right floated">
                <Link to={"/register"}>สมัครแม่บ้าน</Link>
                <span data-toggle="modal" data-target="#statusModal" className="menu-items">ตรวจสอบสถานะใบสมัคร</span>
              </div>
              : null
          }
        </div>
      </div>
      <CheckStatusModal history={history} />
    </>
  );
}


class CheckStatusModal extends React.Component {

  constructor() {
    super();
    this.state = {
      birth_day: null,
      verified: false
    }
  }

  onVerify = recaptchaResponse => {
    this.setState({
      verified: true
    });
  };


  render() {
    let recaptcha_key = "6LeJhuIZAAAAAPQfSvXCIqgJ5xYD7Z5MCxLZVczD";

    if (window.location.hostname === 'localhost') {
      recaptcha_key = "6Ldd_uEZAAAAAGT5U4ge-t8fbWl7_QjSkJqM3ePc";
    } else {
      recaptcha_key = "6LdieT4bAAAAAFnj6CTdNekyS8Ja850K91fTWF5X";
    }
    return (
      <div ref={(ref) => this.$modal = window.$(ref)} className="modal fade" id="statusModal" tabIndex="-1" role="dialog" aria-labelledby="statusModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header custom-modal-header">
              ตรวจสอบสถานะใบสมัคร
            </div>
            <div className="modal-body">
              <form className={"checking-status-form"}
                ref={(ref) => this.$form = window.$(ref)}
                onSubmit={async (e) => {
                  e.preventDefault();
                  let submitBtn = this.$form.find('button[type="submit"]');
                  if (submitBtn.hasClass('loading')) { return; }
                  submitBtn.addClass('loading');
                  e.preventDefault();
                  let data = {};
                  let count_object = 0;
                  this.$form.serializeArray().map((field) => {
                    if (field.value !== "") {
                      count_object++;
                      data[field.name] = field.value;
                    }
                  });
                  if (count_object < 3) {
                    Helper.messageTop({ message: 'กรอกข้อมูลให้ครบถ้วน' });
                    submitBtn.removeClass('loading');
                    this.captcha.reset()
                    return;
                  }
                  if (!this.state.verified) {
                    Helper.messageTop({ message: 'กรุณายืนยันตัวตน' });
                    submitBtn.removeClass('loading');
                    return;
                  }
                  let phoneNumber = '+66' + data.phoneNumber.substr(1, 9);
                  let _data = [];
                  await db.collection("provider_users_data").where('phoneNumber', "==", phoneNumber).get().then((doc) => {
                    doc.docs.forEach((element) => {
                      if (element.data().birthDay === data.birthDay && element.data().personalNo === data.personalNo) {
                        _data.push({ ...element.data(), id: element.id });
                      }
                    });
                  })
                  console.log(_data);
                  if (_data.length > 0) {
                    this.props.history.push({
                      pathname: '/status-checking/' + _data[0].id,
                      search: null,
                      state: null
                    });
                    this.$modal.modal('hide')
                  }
                  if (_data.length === 0) {
                    Helper.messageTop({ message: 'ไม่พบรายละเอียดผู้สมัคร' });
                    this.captcha.reset();
                    submitBtn.removeClass('loading');
                    return;
                  }
                  this.setState({
                    birth_day: null
                  }, () => {
                    submitBtn.removeClass('loading');
                    this.captcha.reset();
                    this.$form.find('input').val('');
                  })
                }}
              >
                <div className="form-group">
                  <label>กรุณากรอกเลขบัตรประจำตัวประชาชน</label>
                  <InputMask
                    mask="9 9999 99999 99 9"
                    placeholder={"เลขบัตรประชาชน"}
                    style={{ textAlign: 'center' }}
                    name={"personalNo"}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>กรุณากรอกเบอร์โทรศัพท์</label>
                  <input type="number" className="form-control" placeholder={"เบอร์โทรศัพท์"} name="phoneNumber" />
                </div>
                <div className="form-group">
                  <label>กรุณากรอก วัน เดือน ปีเกิด</label>
                  <DatePicker
                    onChange={date => {
                      this.setState({
                        birth_day: date
                      })
                    }}
                    locale="th-TH"
                    selected={this.state.birth_day}
                    placeholderText={"วัน / เดือน / ปี"}
                    name={"birthDay"}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    withPortal
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled
                    }) => {
                      let now = new Date();
                      // months_th
                      const years = [];
                      let i;
                      for (i = 1959; i <= now.getFullYear(); i++) {
                        years.push(i)
                      }
                      return (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}
                            className={"btn btn-primary "}
                            type={"button"}
                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                          >
                            <i className="fas fa-chevron-left" />
                          </button>
                          <select
                            className={"form-control"}
                            defaultValue={now.getFullYear()}
                            onChange={({ target: { value } }) => changeYear(value)}
                            style={{ borderRadius: 0 }}
                          >
                            {years.map(option => (
                              <option key={option} value={option} >
                                {option + 543}
                              </option>
                            ))}
                          </select>

                          <select
                            className={"form-control"}
                            defaultValue={window.months_th[date.getMonth()]}
                            onChange={({ target: { value } }) =>
                              changeMonth(window.months_th.indexOf(value))
                            }
                            style={{ borderRadius: 0 }}
                          >
                            {window.months_th.map(option => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}
                            className={"btn btn-primary"}
                            style={{ backgroundColor: '#ffa200', borderColor: '#ffa200', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            type={"button"}
                          >
                            <i className="fas fa-chevron-right" />
                          </button>
                        </div>
                      )
                    }}
                    customInput={<CustomDateInput />}
                  />
                  <input type="hidden" name={"birthDay"} value={new Date(this.state.birth_day).getTime()} readOnly={true} />
                </div>
                <div className="form-group">
                  <Reaptcha sitekey={recaptcha_key} onVerify={this.onVerify} ref={e => (this.captcha = e)} />
                </div>
                <button className="beeclean-button btn"
                  style={{ width: '100%' }}
                  type={"submit"}
                >
                  ตรวจสอบ
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function Footer() {
  let history = useHistory();
  //console.log(history.location.pathname);
  if (history.location.pathname === "/omise-complete" || history.location.pathname === "/omise-complete/") { return null; }
  return (
    <>
      <div className={"footer"}>
        <div className={"container"}>
          <div className="row justify-content-md-center">
            <div className="col-lg-3">
              ดาวโหลดแอปได้ที่นี่
              <div className={"footer-social"}>
                <a href={"https://apps.apple.com/us/app/beeclean-%E0%B9%80%E0%B8%A3-%E0%B8%A2%E0%B8%81%E0%B9%81%E0%B8%A1-%E0%B8%9A-%E0%B8%B2%E0%B8%99/id1540422458"}>
                  <img src="./assets/images/apple-store.png" alt="Apple" />
                </a>
              </div>
              <div className={"footer-social"}>
                <a href={"https://play.google.com/store/apps/details?id=com.playonline.cleaning.user"}>
                  <img src="./assets/images/google.png" alt="Google" />
                </a>
              </div>
              <div className="footer-social">
                <a target="_blank" href={"https://www.facebook.com/beecleanapp"} rel="noopener noreferrer">
                  <img src="./assets/images/facebook.png" alt="Facebook" />
                </a>
                <a target="_blank" href={"https://www.instagram.com/beecleanapp/"} rel="noopener noreferrer">
                  <img src="./assets/images/instagram.png" alt="Instagram" />
                </a>
                <a target="_blank" href={"https://twitter.com/beecleanapp"} rel="noopener noreferrer">
                  <img src="./assets/images/twitter.png" alt="Twitter" />
                </a>
              </div>
            </div>
            <div className="col-lg-5">

              <div className="footer-link">
                {/* <Link to={"/customer"}>ลูกค้า</Link> */}
                {/* <Link to={"/service"}>บริการ</Link> */}
                {/* <Link to={"/promotion"}>โปรโมชั่น</Link> */}
                <a href={`${window.mainSide}#priceRate`}>ค่าบริการ</a>
                <a href={`${window.mainSide}/terms`}>เงื่อนไขการให้บริการ</a>
                <a href={`${window.mainSide}/privacy-policy`}>นโยบายความเป็นส่วนตัว</a>
                {/* <Link to={"/customer"}>ความปลอดภัย และการคุ้มครอง</Link> */}
              </div>
              <div className={"row"} style={{ marginTop: '1rem' }}>
                <div className="col-lg-8">
                  {/* 55/632 โครงการสุโขทัยอเวนิว 99 <br />
                  ซอย แจ้งวัฒนะ ปากเกร็ด 33 <br />
                  ถนน บอนด์สตรีท, บ้านใหม่, ปากเกร็ด <br />
                  นนทบุรี 11120 */}
                  50/804 หมู่ที่ 9 ถนนบอนด์สตรีท ตำบลบางพลู อำเภอปากเกร็ด จังหวัดนนทบุรี 11120
                </div>
                <div className="col-lg-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Home() {

  return (
    <div className={"main-image-bg"}>
      <img alt={'Slide'} src={"assets/images/cover.jpg"} style={{ maxWidth: '100%' }} />
      <div className="main-image-bg-bottom">
        <a href="https://beefast.app/beeclean">เรียกแม่บ้าน กดที่นี่</a>
      </div>
    </div>
  );
}


class BookingTotal extends React.Component {
  constructor(props) {
    super(props);
    let qs = Helper.getParameterByName('qs');
    let month = false;
    let year = false;
    if (qs) {
      let q = qs.split('-')
      month = parseFloat(q[0]) - 1;
      year = parseFloat(q[1]);
    }
    this.state = {
      month: month,
      year: year,
      is_loading: true,
      all_booking: 0,
      condo_dome: {
        max_40: 0,
        max_80: 0,
        max_120: 0,
      },
      town_home: {
        rage_80_120: 0,
        rage_125_150: 0,
        rage_150_250: 0,
      },
      status_count: 0,
      provideruserOnline_count: 0,
      pre_count: 0,
      now_count: 0,
      all_orders: 0,
      all_wait: 0,
      all_complete: 0,
      all_fail: 0,
      order_today: 0,
      order_day: 0,
      timerage: qs ? 'รายเดือน' : 'alltime',
      status_count: {
        accept: 0,
        arrive: 0,
        cancel: 0,
        done: 0,
        finish: 0,
        finish1: 0,
        finish2: 0,
        going: 0,
        wait: 0,
        working: 0,
        refund: 0,
        reverse: 0,
      }
    }
  }
  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    let now_count = 0;
    let pre_count = 0;
    let order_today = 0;
    let all_complete = 0;
    let all_fail = 0;
    let all_wait = 0;
    let order_day = 0;
    let provideruserOnline_count = 0;
    let status_count = {
      accept: 0,
      arrive: 0,
      cancel: 0,
      done: 0,
      finish: 0,
      finish1: 0,
      finish2: 0,
      going: 0,
      wait: 0,
      working: 0,
      refund: 0,
      reverse: 0,
    };
    let condo_dome = {
      max_40: 0,
      max_80: 0,
      max_120: 0,
    };
    let town_home = {
      rage_80_120: 0,
      rage_125_150: 0,
      rage_150_250: 0,
    }

    const today = new Date();
    today.setHours(0, 0, 1);
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1);
    let today_ref = await db.collection('books').where('bookedAt', "<", tomorrow.getTime()).where('bookedAt', '>=', today.getTime()).get();
    order_today = (await today_ref).size;
    let allRef = await db.collection('books');
    let qs = {};
    if (this.state.timerage === 'รายวัน') {
      let day = this.state.day;
      let month = parseFloat(this.state.month);
      let year = this.state.year;
      qs.startDate = new Date(year, month, day);
      qs.startDate.setHours(0, 0, 1);
      qs.endDate = new Date(qs.startDate)
      qs.endDate.setDate(qs.endDate.getDate());
      qs.endDate.setHours(23, 59, 0);
    }
    if (this.state.timerage === 'รายเดือน') {
      qs.startDate = new Date(this.state.year, parseFloat(this.state.month), 2);
      qs.startDate.setHours(0, 0, 1);
      qs.endDate = new Date(this.state.year, parseFloat(this.state.month) + 1, 2);
      qs.endDate.setHours(0, 0, 1);
    }
    if (this.state.timerage === 'รายปี') {
      qs.startDate = new Date(this.state.year, 0, 2);
      qs.startDate.setHours(0, 0, 1);
      qs.endDate = new Date(this.state.year + 1, 0, 2);
      qs.endDate.setHours(0, 0, 1);
    }
    if (qs.startDate) {
      qs.startDate = qs.startDate.getTime();
    }
    if (qs.endDate) {
      qs.endDate = qs.endDate.getTime();
    }

    if (this.state.timerage !== 'alltime') {
      allRef = allRef.where('bookedAt', "<", parseFloat(qs.endDate)).where('bookedAt', '>=', parseFloat(qs.startDate));
    }
    let p = [];
    allRef = await allRef.get();
    let all_orders = allRef.size;
    let timerage = this.state.timerage;
    let providerUID = this.state.providerUID || false;
    let userUID = this.state.userUID || false;
    function returnAllTime(bookedAt, providerUserUid, _userUid) {
      if (
        (!providerUID && !userUID
          && ((timerage !== 'alltime'
            && bookedAt < qs.endDate
            && bookedAt >= qs.startDate)
            || timerage === 'alltime')
        )
        || (providerUID && providerUID === providerUserUid)
        || (userUID && userUID === _userUid)
      ) {
        return true;
      } else {
        return false;
      }
    }
    for (const element of allRef.docs) {
      if (element.data().address && element.data().address.placeTypeUid === '3e47mzTQ3Q8ZC8feInM3') {
        // คอนโด/หอพัก
        if (element.data().address && element.data().address.placeSubTypeUid === 0) { condo_dome.max_40++; }
        if (element.data().address && element.data().address.placeSubTypeUid === 1) { condo_dome.max_80++; }
        if (element.data().address && element.data().address.placeSubTypeUid === 2) { condo_dome.max_120++; }
      }
      if (element.data().address && element.data().address.placeTypeUid === '4VJM9nAwtBThwNiBopWE') {
        // ทาวโฮม/บ้านเดี่ยว
        if (element.data().address && element.data().address.placeSubTypeUid === 0) { town_home.rage_80_120++; }
        if (element.data().address && element.data().address.placeSubTypeUid === 1) { town_home.rage_125_150++; }
        if (element.data().address && element.data().address.placeSubTypeUid === 2) { town_home.rage_150_250++; }
      }
      if (this.state.providerUID) {
        element.data().providerUserUid = this.state.providerUID;
      }
      if (element.data().bookStatus === 'finish' && parseFloat(element.data().starFromProvider) > 0) {
        all_complete++;
      }
      if (element.data().bookStatus === 'cancel' && element.data().paymentStatus !== 'refund') {
        all_fail++;
      }
      if (element.data().bookStatus === 'wait') {
        all_wait++;
      }
      if (returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        order_day++;
      }
      if (element.data().bookStatus === 'accept'
        && returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        status_count.accept++;
      }
      if (element.data().bookStatus === 'arrive'
        && returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        status_count.arrive++;
      }
      if (element.data().bookStatus === 'cancel' && element.data().paymentStatus !== 'refund'
        && returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        status_count.cancel++;
      }
      if (element.data().bookStatus === 'done'
        && returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        status_count.done++;
        status_count.finish1++;
      }
      if (
        element.data().bookStatus === 'finish'
        && returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        status_count.finish++;
        if (parseFloat(element.data().starFromProvider) === 0) {
          status_count.finish1++;
        }
        if (parseFloat(element.data().starFromProvider) > 0) {
          status_count.finish2++;
        }
      }
      if (element.data().bookStatus === 'going'
        && returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        status_count.going++;
      }
      if (element.data().bookStatus === 'wait'
        && returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        status_count.wait++;
      }
      if (element.data().bookStatus === 'working'
        && returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        status_count.working++;
      }
      if (element.data().paymentStatus === 'reverse'
        && returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        status_count.reverse++;
      }
      if (element.data().paymentStatus === 'refund'
        && returnAllTime(element.data().bookedAt, element.data().providerUserUid, element.data().userUid)) {
        status_count.refund++;
      }
      if (element.data().bookType === 'now') {
        now_count++;
      }
      if (element.data().bookType === 'pre') {
        pre_count++;
      }
      let _providerUser = db.doc('provider_users/' + element.data().providerUser.uid).get();
      p.push(_providerUser)
    }

    let fetch = await Promise.all(p);
    for (const doc of fetch) {
      // console.log(doc)
      let providerUserData = doc.data();
      if (providerUserData && providerUserData.isOnline && !this.state.userUID && !this.state.providerUID) {
        provideruserOnline_count++;
      }
    }

    this.setState({
      status_count: status_count,
      provideruserOnline_count: provideruserOnline_count,
      pre_count: pre_count,
      now_count: now_count,
      all_orders: all_orders,
      all_wait: all_wait,
      all_complete: all_complete,
      all_fail: all_fail,
      order_today: order_today,
      order_day: order_day,
      condo_dome: condo_dome,
      town_home: town_home,
      is_loading: false
    })
    return;
  }

  render() {
    return (
      <div style={{ padding: 5 }}>
        {this.state.is_loading ? <strong>กำลังดึงข้อมูล........</strong> : null}
        <div>จำนวน Booking ทั้งหมดที่เคยเรียก <strong>{Helper.numberFormat(this.state.all_orders)}</strong></div>
        <div><strong>สถานะ</strong></div>
        <ul>
          <li>รอ <strong>{Helper.numberFormat(this.state.status_count.wait)}</strong></li>
          <li>ยอมรับ <strong>{Helper.numberFormat(this.state.status_count.accept)}</strong></li>
          <li>กำลังเดินทาง <strong>{Helper.numberFormat(this.state.status_count.going)}</strong></li>
          <li>กำลังทำงาน <strong>{Helper.numberFormat(this.state.status_count.working)}</strong></li>
          <li>ถึงแล้ว <strong>{Helper.numberFormat(this.state.status_count.arrive)}</strong></li>
          <li>เสร็จงาน <strong>{Helper.numberFormat(this.state.status_count.finish)}</strong></li>
          <li>ยกเลิก <strong>{Helper.numberFormat(this.state.status_count.refund)}</strong></li>
          <li>คืนเงิน <strong>{Helper.numberFormat(this.state.status_count.reverse)}</strong></li>
        </ul>
        <div>คอนโด/หอพัก</div>
        <ul>
          <li>ไม่เกิน 40 ตรม. <strong>{Helper.numberFormat(this.state.condo_dome.max_40)}</strong> booking</li>
          <li>ไม่เกิน 80 ตรม. <strong>{Helper.numberFormat(this.state.condo_dome.max_80)}</strong> booking</li>
          <li>ไม่เกิน 120 ตรม. <strong>{Helper.numberFormat(this.state.condo_dome.max_120)}</strong> booking</li>
        </ul>
        <div>ทาว์นโฮม/บ้านเดี่ยว</div>
        <ul>
          <li>80-120 ตรม. <strong>{Helper.numberFormat(this.state.town_home.rage_80_120)}</strong> booking</li>
          <li>125-150 ตรม. <strong>{Helper.numberFormat(this.state.town_home.rage_125_150)}</strong> booking</li>
          <li>150-250 ตรม. <strong>{Helper.numberFormat(this.state.town_home.rage_150_250)}</strong> booking</li>
        </ul>
      </div>
    )
  }
}