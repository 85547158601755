import React from 'react';
import ReactDOM from 'react-dom';
// import ComfirmModal from './Components/ConfirmModal';
import MessageTop from './Components/MessageTop';

const Compress = require('compress.js').default;
const _compress = new Compress();

export const Helper = {
  async resizeImageFn(file, options) {
    const resizedImage = await _compress.compress([file], {
      size: 20, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: options && options.maxWidth ? options.maxWidth : 500, // the max width of the output image, defaults to 1920px
      maxHeight: options && options.maxHeight ? options.maxHeight : 500, // the max height of the output image, defaults to 1920px
      resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    // return img;
    const base64str = img.data
    const imgExt = img.ext
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    return resizedFiile;
  },
  chkDigitPid(p_iPID) {
    var total = 0;
    var iPID;
    var chk;
    var Validchk;
    iPID = p_iPID.replace(/-/g, "");
    Validchk = iPID.substr(12, 1);
    var j = 0;
    var pidcut;
    for (var n = 0; n < 12; n++) {
      pidcut = parseInt(iPID.substr(j, 1));
      total = (total + ((pidcut) * (13 - n)));
      j++;
    }

    chk = 11 - (total % 11);

    if (chk == 10) {
      chk = 0;
    } else if (chk == 11) {
      chk = 1;
    }
    if (chk == Validchk) {
      // alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
      return true;
    } else {
      // alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
      return false;
    }

  },
  getDateThai(_date, options) {
    let date = new Date(_date);
    let time = '';
    if (options && options.is_show_time) {
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hour < 10) {
        hour = '0' + date.getHours()
      }
      if (minute < 10) {
        minute = '0' + date.getMinutes()
      }
      if (second < 10) {
        second = '0' + date.getSeconds()
      }
      time = ' - ' + hour + ':' + minute;
    }
    let month = window.months_th[parseFloat(date.getMonth())];
    if (options && options.month_number) {
      if (JSON.parse(date.getMonth()) < 10) {
        month = '0' + date.getMonth()
      }
    }
    return date.getDate() + ' ' + month + ' ' + (date.getFullYear() + 543) + time;
  },
  getTime(_date, options) {
    let date = new Date(_date);
    let time = '';
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    if (hour < 10) {
      hour = '0' + date.getHours()
    }
    if (minute < 10) {
      minute = '0' + date.getMinutes()
    }
    if (second < 10) {
      second = '0' + date.getSeconds()
    }
    time = hour + ':' + minute;
    return time ;
  },
  getDateNumber(_date, options) {
    let date = new Date(_date);
    let time = '';
    if (options && options.is_show_time) {
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      if (hour < 10) {
        hour = '0' + date.getHours()
      }
      if (minute < 10) {
        minute = '0' + date.getMinutes()
      }
      if (second < 10) {
        second = '0' + date.getSeconds()
      }
      time = ' - ' + hour + ':' + minute + ':' + second;
    }
    let month = (date.getMonth() + 1);
    if (options && options.month_number) {
      if (JSON.parse(date.getMonth()) < 10) {
        month = '0' + (date.getMonth() + 1)
      }
    }
    return date.getDate() + '-' + month + '-' + (date.getFullYear()) + time;
  },
  getDateToEdit(date) {
    // MMMM d, yyyy h:mm aa
    var timestampInSeconds = Math.floor(date / 1000);
    var _date = new Date(timestampInSeconds * 1000);
    return _date;
  },
  messageTop(options) {
    Helper.reactToDom(window.$('body'), <MessageTop {...options} />)
  },
  reactToDom($dom, Component, props) {
    if (typeof Component === 'function') {
      ReactDOM.render(React.createElement(Component, props, null), document.createElement('div'), function () {
        $dom.append(ReactDOM.findDOMNode(this));
      });
    }
    if (typeof Component === 'object') {
      ReactDOM.render(Component, document.createElement('div'), function () {
        $dom.append(ReactDOM.findDOMNode(this));
      });
    }
  },
  // confirmModal(options) {
  //   Helper.reactToDom(window.$('body'),
  //     <ComfirmModal  {...options} />
  //   );
  // },
  setNewImageFileName(file) {
    let date = new Date();
    let file_type = file.name.substr(file.name.indexOf('.'), file.name.indexOf('.') + 3);
    let file_name = file.name.substr(0, file.name.indexOf('.'));
    return file_name + Date.parse(date) + file_type;
  },
  setCookie(cname, cvalue, exdays) {

    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    // cvalue = window.escape(cvalue);
    cvalue = window.escape(cvalue);
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        // c = window.unescape(c);
        c = window.unescape(c);
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  getAllUrlParams(url) {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');

        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {

          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string') {
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  },
  MonthTh() {
    return ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน.", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
  },
  Month() {
    return ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  },
  Today() {
    let today = new Date();
    // let dd = String(today.getDate()).padStart(2, '0');
    // let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // let yyyy = today.getFullYear();

    // today = yyyy + '-' + mm + '-' + dd;

    // today = mm + '-' + dd + '-' + yyyy;
    return today;
  },
  FormatDateNumber(data) {
    let today = new Date();
    let current_datetime = new Date(data);
    let dd = String(current_datetime.getDate()).padStart(2, '0');
    let mm = String(current_datetime.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = current_datetime.getFullYear();
    if (current_datetime.getFullYear() > today.getFullYear()) {
      yyyy = today.getFullYear()
    }
    let formatted_date = yyyy + '-' + mm + '-' + dd + ' 00:00:00';
    return formatted_date;
  },
  FormatDate(data) {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let current_datetime = new Date(data.replace(' ', 'T'))
    let formatted_date = current_datetime.getDate() + " " + months[current_datetime.getMonth()] + " " + current_datetime.getFullYear()
    return formatted_date;
  },
  FormatDateTh(data) {
    const months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    let current_datetime = new Date(data.replace(' ', 'T'));
    let formatted_date = current_datetime.getDate() + " " + months[current_datetime.getMonth()] + " " + (current_datetime.getFullYear() + 543)
    return formatted_date;
  },
  FormatTime(data) {

    let current_datetime = new Date(data.replace(' ', 'T'))
    let Hour = current_datetime.getHours() < 10 ? '0' + current_datetime.getHours() : current_datetime.getHours();
    let Minute = current_datetime.getMinutes() < 10 ? '0' + current_datetime.getMinutes() : current_datetime.getMinutes();
    let formatted_date = Hour + ":" + Minute;
    return formatted_date;
  },
  dataDiff(start_date, end_date, diff_type) {
    const date1 = new Date(start_date.replace(' ', 'T'));
    const date2 = new Date(end_date.replace(' ', 'T'));
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diff_type === 'time') {
      return diffTime;
    }
    return diffDays;
  },
  summaryItems(cart) {
    let summary = {};
    summary.total_price = 0;
    summary.total_deposit = 0;
    summary.total_discount = 0;
    summary.shipment_price = cart && cart.shipment_price ? cart.shipment_price : 0;
    summary.shipment_discount = 0;
    summary.voucher_discount = 0;
    summary.grand_total_price = 0;
    summary.items_amount = 0;


    if (cart && cart.items && cart.items.length > 0) {
      cart.items.forEach((item) => {
        if (item.is_select) {
          summary.total_price = summary.total_price + parseFloat(item.price);
          summary.total_discount = summary.total_discount + parseFloat(item.discount);
          summary.items_amount = summary.items_amount + parseFloat(item.quantity);
          summary.total_deposit = summary.total_deposit + parseFloat(item.deposit);
        }
      });
    }

    summary.grand_total_price = (summary.total_price - summary.total_discount) + parseFloat(summary.shipment_price) + parseFloat(summary.total_deposit);
    if (cart && cart.voucher_group) {
      if (parseFloat(cart.voucher_group.vg_type) === 1) {
        summary.voucher_discount = cart.voucher_group.vg_value + '%';
        summary.grand_total_price = (parseFloat(summary.grand_total_price) * parseFloat(cart.voucher_group.vg_value)) / 100;
      }
      if (parseFloat(cart.voucher_group.vg_type) === 2) {
        summary.voucher_discount = '฿' + Helper.numberFormat(cart.voucher_group.vg_value);
        summary.grand_total_price = parseFloat(summary.grand_total_price) - parseFloat(cart.voucher_group.vg_value);
      }
    }


    return summary;
  },
  numberFormat(number) {
    number = parseFloat(number);
    if (isNaN(number)) {
      number = 0;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  objToGetParameter(obj) {
    let str = "";
    for (let key in obj) {
      if (str !== "") {
        str += "&";
      }
      str += key + "=" + obj[key];
    }
    return str;
  },

}