import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { functions } from "firebase";

// const admin = require('firebase-admin');
// const serviceAccount = require("./assets/banpuen-app-firebase-adminsdk-3klek-079fb87c24.json");
// console.log(serviceAccount);
// var defaultApp = admin.initializeApp({
//   credential: admin.credential.cert(serviceAccount),
//   databaseURL: "https://banpuen-app.firebaseio.com"
// });
// console.log(defaultApp.name)
// export const firebase = defaultApp;

var firebaseConfig = {
  apiKey: "AIzaSyB6H-6eUX3kkeMltrkuphdJxErN_9C51zI",
  authDomain: "beeclean-app-66b97.firebaseapp.com",
  projectId: "beeclean-app-66b97",
  storageBucket: "beeclean-app-66b97.appspot.com",
  messagingSenderId: "416608692434",
  appId: "1:416608692434:web:3aae6dc4074cf9be30a50b",
  measurementId: "G-88NG6CLVJ6"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);
if (isLocalhost) {

  console.log('local');

  // firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
}

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;

  const userRef = db.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email, displayName, photoURL } = user;
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    const userDocument = await db.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data()
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};
