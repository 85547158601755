import React from 'react';

export default class CustomDateInput extends React.Component {
    render() {
        let date = new Date(this.props.value);
        let time ='';
        if(this.props.is_show_time) {
            time = ' - ' + date.getHours()+ ':' + date.getMinutes();
        }
        return (
            <>
                <button className="form-control"
                    onClick={this.props.onClick}
                    type={"button"}
                    style={{ color: !this.props.value ? '#ccc' : null, textAlign: "left" }}
                >
                    {this.props.value ? date.getDate() + ' ' + window.months_th[parseFloat(date.getMonth())] + ' ' + (date.getFullYear() + 543) + time : 'วัน เดือน ปี'}
                </button>
            </>
        );
    }
}