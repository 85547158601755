import React from 'react';

export default class MessageTop extends React.Component {

    render() {
        let time = setTimeout(() => {
            this.alert.remove();
            clearTimeout(time);
        }, 5000);
        let alertclass = "alert";
        if (this.props.type && this.props.type == 'success') {
            alertclass += " alert-success"
        } else {
            alertclass += " alert-danger"
        }
        return (
            <div className={"alert_top animate__animated animate__bounceInDown"}
                ref={(ref) => { this.alert = window.$(ref) }}
                onClick={() => {
                    this.alert.remove();
                }}
            >
                <div className={alertclass} role="alert">
                    {this.props.message}
                </div>
            </div>
        );
    }
}