import React from 'react';
import { Link } from 'react-router-dom';
import { db } from './../firebase';
import { Helper } from './../Helper'

export default class Promotion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: props.match.params.promotion_code || false,
            is_loading: true
        }
    }


    componentDidMount() {
        this.getPromotion();
    }

    async getPromotion() {
        let ref = await db.collection("promotions").where("voucherCode", '==', this.state.code).where("isShowWeb", '==', true).get();
        let promotion = false;
        if (!ref.empty) {
            promotion = ref.docs[0].data();
        }
        this.setState({
            promotion: promotion,
            is_loading: false,
        })
    }

    render() {
        return (
            <>
                <div className="container content-bg">
                    <div className="header">โปรโมชั่น</div>
                    {
                        this.state.is_loading ?
                            <div style={{ padding: '1rem', textAlign: 'center' }}>
                                <div className={"ui inline loader active"} />
                            </div>
                            : null
                    }
                    {
                        !this.state.is_loading
                            && this.state.promotion ?
                            <PromotionDetail
                                promotion={this.state.promotion}
                            />
                            : null
                    }
                    {
                        !this.state.is_loading
                            && !this.state.promotion ?
                            <div style={{ padding: '1rem', textAlign: 'center' }}>
                                <h4>ไม่พอข้อมูล</h4>
                            </div>
                            : null
                    }
                </div>
            </>
        );
    }
}

export class PromotionDetail extends React.Component {
    copyCode() {
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(this.props.promotion.voucherCode);
        Helper.messageTop({ message: `Copied promotion code : ${this.props.promotion.voucherCode} `, type: 'success' })
        this.btn.removeClass('loading');
    }
    render() {
        console.log(this.props.promotion);
        let promotion = this.props.promotion;
        return (
            <>
                <div className='card'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={promotion.imageURL} style={{ maxWidth: '100%' }} />
                    </div>

                    <div className='card-body'>
                        <h1 style={{ textAlign: 'center', color: '#000', marginBottom: 20 }}>{promotion.voucherCode}</h1>
                        <div style={{ textAlign: 'center' }}>
                            <button
                                ref={(ref) => { this.btn = window.$(ref) }}
                                className='btn btn-lg btn-success'
                                onClick={() => {
                                    if (this.btn.hasClass('loading')) { return; }
                                    this.btn.addClass('loading');
                                    this.copyCode()
                                }}
                            >คัดลอกโค้ด</button>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}