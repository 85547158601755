import React from 'react';
import { Helper } from '../Helper';
const AndroidURL = "https://play.google.com/store/apps/details?id=com.playonline.cleaning.user";
const IOSURL = "https://apps.apple.com/us/app/beeclean-%E0%B9%80%E0%B8%A3-%E0%B8%A2%E0%B8%81%E0%B9%81%E0%B8%A1-%E0%B8%9A-%E0%B8%B2%E0%B8%99/id1540422458";
export default class Download extends React.PureComponent {
    constructor() {
        super();
    }

    componentDidMount() {
        // const s = document.createElement('script');
        // s.type = 'text/javascript';
        // s.async = true;
        // s.innerHTML = `var domIsReady=function(e){e&&\"function\"==typeof e?\"ie\"!=(document.attachEvent&&void 0!==document.attachEvent?\"ie\":\"not-ie\")?document.addEventListener(\"DOMContentLoaded\",function(){return e()}):document.attachEvent(\"onreadystatechange\",function(){if(\"complete\"===document.readyState)return e()}):console.error(\"The callback is not a function!\")};!function(e,t,n,o){n(function(){setTimeout(function(){navigator.userAgent.toLowerCase().indexOf(\"android\")>-1?t.location.href=\"${providerAndroidUrl}\":navigator.userAgent.toLowerCase().indexOf(\"iphone\")>-1&&(t.location.href=\"${providerIOSUrl}\")},500)})}(document,window,domIsReady);`;

        // document.body.appendChild(s);

        setTimeout(() => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
                console.log('android')
                window.location.href = AndroidURL;
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                console.log('ios')
                window.location.href = IOSURL;
            }
        }, 1000);
    }

    render() {
        return (
            <>
                <div class="container content-bg">
                    <div class="header">ดาวน์โหลด BEEclean</div>
                    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                        <a href={AndroidURL}>
                            <img src="./assets/images/dl-googleplay-btn.png" alt="Google" />
                        </a>
                        <a href={IOSURL}>
                            <img src="./assets/images/dl-appstore-btn.png" alt="Apple" />
                        </a>
                    </div>
                </div>
            </>
        );
    }
}