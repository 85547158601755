import React from 'react';
import { Link } from 'react-router-dom';
export default class RegisterSuccess extends React.Component {
    render() {
        return (
            <div className={"container content-bg register-success"}>
                <div className="header">
                    เราได้รับข้อมูลของท่านแล้ว<br />
                    กรุณาแจ้งชื่อ และ ส่งเอกสาร ทางไลน์ @beecleanjob
                    {/* ทางเราจะติดต่อกลับภายใน 24ชม. ค่ะ */}
                </div>
                <div style={{ textAlign: 'center' }}>
                    เพื่อความสะดวกและรวดเร็ว กรุณาแอดไลน์มาที่ @beecleanjob แล้วแจ้งชื่อนามสกุล 
                </div>
                <div style={{ textAlign: 'center', marginTop: '2rem',marginBottom: '2rem'}}>
                    <a className="line-button" href={"https://line.me/R/ti/p/~@beecleanjob"} style={{paddingLeft: '2rem',paddingRight: '2rem'}}>แอดไลน์ @beecleanjob เริ่มงานแม่บ้าน</a>
                </div>
                <div style={{ textAlign: 'center',marginBottom: 15 }}>
                    <img src={"./assets/images/9.png"} alt={"Icon"} style={{ maxWidth: '100%', height: 400 }} />
                </div>
            </div>
        );
    }
}