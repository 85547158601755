import React from 'react';
import { Link } from 'react-router-dom';
import { db } from './../firebase';
import { Helper } from './../Helper';
import { PromotionDetail } from './Promotion';

export default class Promotion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true
        }
    }


    componentDidMount() {
        this.getPromotion();
    }

    async getPromotion() {
        let ref = await db.collection("promotions").where("isShowWeb", '==', true).where("promotionType", "!=", "done").get();
        let promotions = [];
        if (!ref.empty) {
            for (const doc of ref.docs) {
                promotions.push({ ...doc.data(), uid: doc.id })
            }
        }
        this.setState({
            promotions: promotions,
            is_loading: false,
        })
    }

    render() {
        return (
            <>
                <div className="container content-bg">
                    <div className="header">โปรโมชั่น</div>
                    {
                        this.state.is_loading ?
                            <div style={{ padding: '1rem', textAlign: 'center' }}>
                                <div className={"ui inline loader active"} />
                            </div>
                            : null
                    }
                    {
                        !this.state.is_loading
                            && this.state.promotions
                            && this.state.promotions.length > 0 ?
                            this.state.promotions.map((promotion, i) => {
                                return (
                                    <React.Fragment>
                                        {
                                            i > 0 ?
                                                <hr />
                                                : null
                                        }
                                        < PromotionDetail
                                            promotion={promotion}
                                        />
                                    </React.Fragment>
                                )
                            })
                            : null
                    }
                    {
                        !this.state.is_loading
                            && (
                                !this.state.promotions
                                || this.state.promotions.length == 0
                            ) ?
                            <div style={{ padding: '1rem', textAlign: 'center' }}>
                                <h4>ไม่พอข้อมูล</h4>
                            </div>
                            : null
                    }
                </div>
            </>
        );
    }
}
