import React from 'react';
const providerAndroidUrl = `https://play.google.com/store/apps/details?id=com.playonline.cleaning.provider`;
const providerIOSUrl = `https://apps.apple.com/us/app/%E0%B8%A3-%E0%B8%9A%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%81%E0%B8%A1-%E0%B8%9A-%E0%B8%B2%E0%B8%99-beeclean/id1540422113`;
export default class DownloadProvider extends React.Component {
    constructor() {
        super();
    }

    componentDidMount() {
        // const s = document.createElement('script');
        // s.type = 'text/javascript';
        // s.async = true;
        // s.innerHTML = `var domIsReady=function(e){e&&\"function\"==typeof e?\"ie\"!=(document.attachEvent&&void 0!==document.attachEvent?\"ie\":\"not-ie\")?document.addEventListener(\"DOMContentLoaded\",function(){return e()}):document.attachEvent(\"onreadystatechange\",function(){if(\"complete\"===document.readyState)return e()}):console.error(\"The callback is not a function!\")};!function(e,t,n,o){n(function(){setTimeout(function(){navigator.userAgent.toLowerCase().indexOf(\"android\")>-1?t.location.href=\"${providerAndroidUrl}\":navigator.userAgent.toLowerCase().indexOf(\"iphone\")>-1&&(t.location.href=\"${providerIOSUrl}\")},500)})}(document,window,domIsReady);`;

        // document.body.appendChild(s);
        setTimeout(() => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
                console.log('android')
                window.location.href = providerAndroidUrl;
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                console.log('ios')
                window.location.href = providerIOSUrl;
            }
        }, 1000);
    }

    render() {
        return (
            <>
                <div className="container content-bg">
                    <div className="header">ดาวน์โหลด BEEclean สำหรับแม่บ้าน</div>
                    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                        <a href={providerAndroidUrl}>
                            <img src="./assets/images/dl-googleplay-btn.png" alt="Google" />
                        </a>
                        <a href={providerIOSUrl}>
                            <img src="./assets/images/dl-appstore-btn.png" alt="Apple" />
                        </a>
                    </div>
                </div>
            </>
        );
    }
}