import React from 'react';
import { Link } from 'react-router-dom';
import { db } from './../firebase';
export default class StatusChecking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            user: false,
            user_id: props.match.params.users_id || false,
        }
    }
    componentDidMount() {
        this.fetchUser();
    }
    async fetchUser() {
        let user = {};
        await db.doc("provider_users_data/" + this.state.user_id).get().then(async (doc) => {
            user = { ...doc.data() };
            if (user.traningUID) {
                await db.doc("training_date/" + user.traningUID).get().then(async (doc) => {
                    user.educateDate = doc.data().date;
                })
            }
        })
        this.setState({
            user: user,
            is_loading: false,
        })
    }



    render() {
        return (
            <div className={"container content-bg status-checking"}>
                <div className="header">
                    ตรวจสอบสถานะใบสมัคร
                </div>
                <div className={"status-checking-content"}>
                    <div className="row">
                        <div className="col-sm-12 col-lg-5" >
                            <div className="custom-box">
                                <div className="custom-box-header">
                                    ข้อมูลใบสมัคร
                            </div>
                                {
                                    this.state.user ?
                                        <div className="custom-box-body">
                                            <div>เลขบัตรประจำตัวประชาชน : {this.state.user.personalNo}</div>
                                            <div>ชื่อ : {this.state.user.firstName}</div>
                                            <div>นามสกุล : {this.state.user.lastName}</div>
                                            <div>เพศ : {this.state.user.gender}</div>
                                            <div>เบอร์โทร : {this.state.user.phoneNumber.replace('+66', '0')}</div>
                                            <div>Line ID : {this.state.user.lineID}</div>
                                            <div>วันเกิด : {new Date(parseFloat(this.state.user.birthDay)).getDate()} {window.months_th[new Date(parseFloat(this.state.user.birthDay)).getMonth()]} {new Date(parseFloat(this.state.user.birthDay)).getFullYear() + 543}</div>
                                            <div>จังหวัดที่ต้องการสมัคร : {this.state.user.province}</div>
                                            <div>เขต :
                                                {
                                                    this.state.user.amphoe ?
                                                        this.state.user.amphoe.map((_amphoe, _amphoe_i) => {
                                                            return (_amphoe_i === 0 ? _amphoe : ' , ' + _amphoe)
                                                        })
                                                        : null
                                                }
                                            </div>
                                            <div>โรคประจำตัว : {this.state.user.congenitalDisease}</div>
                                            <div>เบอร์ติดต่อฉุกเฉิน : {this.state.user.emergencyContactNumber.replace('+66', '0')}</div>
                                        </div>
                                        : null
                                }

                                {
                                    this.state.is_loading ?
                                        <div style={{ padding: '1rem', textAlign: 'center' }}>
                                            <div className={"ui inline loader active"} />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-7" >
                            <div className="custom-box">
                                <div className="custom-box-header">
                                    ข้อมูลใบสมัคร
                            </div>
                                {
                                    this.state.user ?
                                        <div className="custom-box-body">
                                            {
                                                this.state.user.status === 'waitForApprove' ?
                                                    <><i className={"fas fa-circle"} style={{ color: '#ffa200' }} /> รอการยืนยัน</>
                                                    : null
                                            }
                                            {
                                                this.state.user.status === 'approved' ?
                                                    <><i className={"fas fa-circle"} style={{ color: 'blue' }} /> ยืนยันแล้ว</>
                                                    : null
                                            }
                                            {
                                                this.state.user.status === 'avaliable' ?
                                                    <><i className={"fas fa-circle"} style={{ color: 'green' }} /> ใช้งานได้</>
                                                    : null
                                            }
                                            {
                                                this.state.user.status === 'ban' ?
                                                    <><i className={"fas fa-circle"} style={{ color: 'red' }} /> ระงับการใช้งาน</>
                                                    : null
                                            }
                                            {/* <div><i className="fas fa-map" /></div> */}
                                            <div style={{marginTop: 10}}>
                                                {/* นัดหมายอบรม {new Date(parseFloat(this.state.user.educateDate)).getDate()} {window.months_th[new Date(parseFloat(this.state.user.educateDate)).getMonth()]} {new Date(parseFloat(this.state.user.educateDate)).getFullYear() + 543} <br />
                                                <i className="fas fa-map" /> สถานที่ 55/632 โครงการสุโขทัยอเวนิว 99 ปากเกร็ด นนทบุรี 11120 */}
                                                <strong>กรุณาแจ้งชื่อ และ ส่งเอกสาร ทางไลน์ @beecleanjob</strong><br/>
                                                เพื่อความสะดวกและรวดเร็ว กรุณาแอดไลน์มาที่ @beecleanjob<br/> แล้วแจ้งชื่อนามสกุล 
                                            </div>
                                            <div style={{ color: 'red' }}>
                                                หมายเหตุ<br />
                                                ต้องเตรียมเอกสาร<br />
                                                1. สำเนาบัตรประชาชน<br />
                                                2. สำเนาหน้าสมุดบัญชีธนาคาร
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    this.state.is_loading ?
                                        <div style={{ padding: '1rem', textAlign: 'center' }}>
                                            <div className={"ui inline loader active"} />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                    <Link className="beeclean-button" to={"/home"} style={{ paddingLeft: '2rem', paddingRight: '2rem' }}>กลับไปหน้าแรก</Link>
                </div>
            </div >
        );
    }
}