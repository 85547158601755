import React from 'react';
import { Helper } from '../Helper';
const axios = require('axios').default;

export default class toAppProvider extends React.Component {

    componen
    componentDidMount() {
        setTimeout(
            function () {
                window.location.href = "beecleanuser://";
            }
                .bind(this),
            500
        );
    }

    render() {
        return (
            <>

            </>
        );
    }
}